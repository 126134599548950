import { routes_names } from "../constants/routes-names"

export function afterEach(to, from) {
  // const setup_flag = process.env.VUE_APP_SETUP_FLAG
  // if(setup_flag=='production') {
    if(from.name==routes_names.signup) {
      document.getElementById('openreplay-script-loader')?.remove?.();
      document.getElementById('hs-script-loader')?.remove?.();
    }
    if(to.name==routes_names.signup) {
      const openreplay_script = document.createElement('script');
      openreplay_script.type = 'text/javascript';
      openreplay_script.src = '/assets/scripts/openreplay.js';
      openreplay_script.id='openreplay-script-loader'
      openreplay_script.async=true
      openreplay_script.defer=true
      document.body.appendChild(openreplay_script);
      const hs_script = document.createElement('script');
      hs_script.type = 'text/javascript';
      hs_script.src = '//js.hs-scripts.com/6005544.js';
      hs_script.id='hs-script-loader'
      hs_script.async=true
      hs_script.defer=true
      document.body.appendChild(hs_script);
    }
  // }
}