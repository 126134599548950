<template>
  <div id="app">
    <AppCommon />
    <router-view />
    <DeveloperSetting />
  </div>
</template>

<script>
import DeveloperSetting from "./components/modals/DeveloperSetting.vue";
import AppCommon from './components/common/App.vue'
import { MEDIA_DEVICES, PERMISSIONS, DEVICE_INFO } from './utils';
import { LOGGER } from './service';
import { Jitsimeet } from './jitsimeet'
import { API } from './service/index';
import { DIALER } from './dialer';
import { mapMutations } from 'vuex';
import _ from 'lodash'
import { BROADCAST } from './broadcast';
import { SET_DEVICE_INFO, SET_JITSIMEET, SET_MEDIA_DEVICES, SET_PERMISSIONS } from './store/constants/mutations';
let shortcut_times = 0
export default {
  name: 'App',
  components: {
    DeveloperSetting,
    AppCommon,
  },
  methods: {
    ...mapMutations([
      SET_JITSIMEET,
      SET_DEVICE_INFO,
      SET_PERMISSIONS,
      SET_MEDIA_DEVICES
    ]),
    loadMonitorScript(){
      if(process.env.NODE_ENV=='production'){
        let head = document.getElementsByTagName('head')[0];
        let monitor_script = document.createElement('script');
        monitor_script.type = 'text/javascript';
        monitor_script.src = "/assets/scripts/monitor.js";
        head.appendChild(monitor_script);
      }
    },
    init(){
      window.voip_app = {}
      API.init()
      DIALER.init()
      const __jitsimeet = Jitsimeet.init();
      this.SET_JITSIMEET(__jitsimeet)
      const __device_info = DEVICE_INFO.init();
      this.SET_DEVICE_INFO(__device_info)
      const __permissions = PERMISSIONS.init();
      this.SET_PERMISSIONS(__permissions)
      const __media_devices = MEDIA_DEVICES.init();
      this.SET_MEDIA_DEVICES(__media_devices)
      BROADCAST.init()
      API.endpoints.dns.deviceInfo()
    },
    onResize(){
      document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
    },
    onBeforeInstallPrompt(Event){
      LOGGER.log('onBeforeInstallPrompt::Event',Event); // e.g., ["web", "android", "windows"]
      Event.userChoice.then((choiceResult) => {
        LOGGER.log(choiceResult.outcome);
      }, (error)=>{
        LOGGER.log(error);
      });
    },
    // events
    onKeyDown(event){
      if(event.altKey && event.ctrlKey && event.shiftKey && event.code=='Slash' && !document.getElementById('dailer-frame')){
        shortcut_times++
        this.openDeveloperModal()
      }
    },
    openDeveloperModal: _.debounce(function(){
      console.log('run 1',shortcut_times)
      if(shortcut_times==5) this.$modal.show('DeveloperSetting')
      shortcut_times=0
    },0.5*1000),
  },
  mounted(){
    this.init()
  },
  created(){ 
    this.loadMonitorScript()
    document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
    window.addEventListener('resize',this.onResize)
    window.addEventListener("beforeinstallprompt", this.onBeforeInstallPrompt);
    window.addEventListener('keydown',this.onKeyDown)
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.onResize)
    window.removeEventListener("beforeinstallprompt", this.onBeforeInstallPrompt);
    window.removeEventListener('keydown',this.onKeyDown)
  },
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

</style>

<style lang="scss">


// login page variables start

$white: #ffffff !default;
$primary: #5576d1;
$font-family-sans-serif: "Montserrat", Helvetica, Arial, sans-serif;
$red: #dc3545 !default;
$body-color: #393939;
$blue: #007bff !default;
$theme-purple: #3c50e0 !default;
$purple: #3c50e0 !default;
$dialer-default-padding: 16px;

.main {
  height: 100%;
  width: 100%;
}

.flip-23-enter-active, .flip-23-leave-active {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-23-enter, .flip-23-leave-to {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@mixin box-shadow-mixin($val) {
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  -ms-box-shadow: $val;
  -o-box-shadow: $val;
  box-shadow: $val;
}
@mixin border-radius($b_r_val) {
  -webkit-border-radius: $b_r_val;
  -moz-border-radius: $b_r_val;
  -ms-border-radius: $b_r_val;
  -o-border-radius: $b_r_val;
  border-radius: $b_r_val;
}
// login page variables start
// start login page

.btn-check:focus + .btn-primary, .btn-primary:focus  {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.0rem rgb(49 132 253 / 50%) !important;
}
input:focus{
  box-shadow: 0 0 0 0.0rem rgb(49 132 253 / 50%) !important;
}
.btn{
  padding:12px 24px;
}
a{
  cursor: pointer;
    text-decoration: none;
}
.reverse-flipped {
  transform: rotateY(180deg);
}
.dialer-social-icon-area-login{
  svg{
    path{
      fill: black;
    }
  }
}



// Loader inside buttons css start
// .loader-container{
//   left:-71px;
//   top:-23px;
//   width:34px;
//   margin-right: -34px;
// }
// .lds-facebook {
//   display: inline-block;
//   position: relative;
//   width: 34px;
//   height: 10px;
// }
// .lds-facebook div {
//   display: inline-block;
//   position: absolute;
//   left: 8px;
//   top:0px;
//   width: 8px;
//   background: #fff;
//   animation: lds-facebook 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
// }
// .lds-facebook div:nth-child(1) {
//   left: 0px;
//   animation-delay: -0.24s;
// }
// .lds-facebook div:nth-child(2) {
//   left: 12px;
//   animation-delay: -0.12s;
// }
// .lds-facebook div:nth-child(3) {
//   left: 24px;
//   animation-delay: 0;
// }
// @keyframes lds-facebook {
//   0% {
//     top: 8px;
//     height: 35px;
//   }
//   50%, 100% {
//     top: 24px;
//     height: 10px;
//   }
// }
// Loader inside buttons css end
// .dialer-main-loader{
//   min-width: 100%;
// }
.login-checkbox-container{
  li{
    .custom-checkbox{
      .custom-control-label{
        margin-left:$dialer-default-padding;
      }
    }
  }
}
.mw-input-container{
  max-width:325px;
  min-width:325px;
}
.cursor_pointer{
  cursor: pointer;
}
.phone-number-select{
  .form-group{
    & > div{
      display: flex;
      justify-content: space-around;
      .custom-radio{
        border: 1px solid $primary;
        border-radius:8px ;
        padding: $dialer-default-padding $dialer-default-padding * 2;
        input{
          margin-right: $dialer-default-padding;
          // margin-left: $dialer-default-padding;
        }
      }
    }
  }
}
.dialer-form-signup-left{
.dialer-formElement{
  &.custom-login-textarea-container{
    .formElement{
      // width: calc(100% - 30px);
      height:auto !important;
      position: relative;
      .custom-login-textarea{
        // position: absolute;
        // padding: 16px 8px 16px 15px;
        // margin-right:$dialer-default-padding;
        height:auto;
        /* width */
        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 50%;
          cursor:pointer;

        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 15%;
          margin:8px;
          cursor:pointer;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $primary;
          border-radius: 15%;
          cursor:pointer;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          // background: #555;
          cursor:pointer;
        }
        &.form-control{
          width: calc(100% - 60px);
        }
      }
    }
  }

}
}

.dialer-login,
.dialer-wrap-initials {
  // display: flex;
  align-items: center;
}
.dialer-login-flex {
  position: relative;
  // background: $white;
  .login-banner-overlay {
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    width: 100%;
    height: 75vh;
    position: absolute;
    z-index: 10;
    background-color: $primary;
    border-radius: 50%;
    width: 106vw;
    height: 100vw;
    top: -65vw;
    left: -3vw;
  }
  .bannerBg {
    background-repeat: no-repeat;
    background-position: 85% -13vh;
    background-size: 60%;
    width: 100%;
    height: 75vh;
    position: absolute;
  }
  .dialer-login {
    // position: absolute;
    position: relative;
    z-index: 11;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .dialer-login-inner {
      // max-width: 350px;
      &.dialer-signup-form{
        // max-width: 1050px;
        max-width: 1100px;
        min-width:1100px;
        .dialer-form-signup-left{
          max-width:350px;
          min-width:300px;
          margin-right: $dialer-default-padding;
          .custom-select,.form-control{
            width: calc(100% - 30px);
            background-color: transparent;
            padding:0px 0.75rem;
            border:0px;
            &:focus{
              box-shadow: 0 0 0 0.0rem rgb(13 110 253 / 25%);
            }
            &:focus-visible{
              outline:0px
            }
            .dropdown-menu.show{
              width: calc(100% - 10px);
            }
          }
        }
        .dialer-form-signup-right{
          border-left:1px solid gray;
          padding:0px $dialer-default-padding 0px 0px;
          .login-checkbox-container{
            li{
              .custom-checkbox{
                .custom-control-label{
                  margin-left:$dialer-default-padding;
                }
              }
              &:nth-child(4){
                input{
                  vertical-align: super;
                }
              }
            }
          }
        }
      }
      .dilaer-login-top {
        color: $white;
        h1 {
          font-size: 32px;
          font-weight: 900;
          font-family: $font-family-sans-serif;
          margin-bottom: 10%;
        }
        h2 {
          font-size: 18px;
          font-weight: 500;
          font-family: $font-family-sans-serif;
        }
        p {
          margin-bottom: 12%;
        }
      }
      .dialer-login-form {
        transition: transform 1s;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        .dialer-form {
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          .dialer-form-inner {
            transition: transform 1s;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            width: 100%;
            background: $white;
            padding: 30px 33px;
            @include box-shadow-mixin(0px 3px 80px rgba(0, 0, 0, 0.1));
            @include border-radius(30px);
            transition: 1s linear;
            img {
              width: 45px;
            }
            .big-image-container{
              .big-image{
                width:380px;
              }
            }
          }
          h3 {
            font-family: $font-family-sans-serif;
            font-size: 18px;
            margin-top: 10%;
            margin-bottom: 10%;
            color: $red;
            color: $body-color;
            font-weight: 600;
            line-height: 1.2;
          }
          .dialer-formElement {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin-bottom: 20px;
            .formElement {
              margin-bottom: 0px;
              position: relative;
              padding: 5px 5px 5px 15px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              background: #f2f2f2;
              border-radius: 50px;
              height: 50px;
              .icon {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                color: $body-color;
              }
              input {
                background: transparent;
                border: 0px solid transparent;
                box-sizing: content-box;
                font-size: 16px;
                width: calc(100% - 64px);
                outline: none;
                border-left: none;
                display: inline-block;
                padding-top: 0px;
                padding-bottom: 0px;
              }
              input:-internal-autofill-selected {
                background: transparent !important;
              }
              .pass-icon {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                position: absolute;
                right: 15px;
                cursor: pointer;
              }
              &.enterCodeSection {
                background: transparent;
                padding: 0px;
                &.newPin{
                  .react-code-input>input {
                    border: 1px solid #a8adb7;
                    // height: 100% !important;
                    width: 100% !important;
                    border-right: none;
                    font-family: Lato;
                    font-size: 20px;
                    color: #525461;
                    text-align: center;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border-radius: 0;
                    -webkit-appearance: initial;
                    &:first-child{
                      border-top-left-radius: 6px;
                      border-bottom-left-radius: 6px;
                    }
                    &:last-child{
                      border-right: 1px solid #a8adb7;
                      border-top-right-radius: 6px;
                      border-bottom-right-radius: 6px;
                    }
                  }
                }
              }
            }
          }
          .formSubmit {
            margin: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
              margin: 30px 0px;
              width: 100%;
              @include border-radius(50px);
              background: $primary;
              text-transform: uppercase;
              font-size: 14px;
              color: $white;
            }
            .dialer-custom-link {
              padding: 0;
              border: none;
              background: transparent;
              color: $blue;
              border-bottom: 2px solid transparent;
              &:hover {
                border-bottom: 2px solid $blue;
              }
            }
          }
          .text-small {
            font-size: 14px;
            margin-left: 15px;
            color: $theme-purple;
          }
        }
        .reverse-flipped {
          transform: rotateY(180deg);
        }
        .error-messages {
          padding: 0px;
          list-style-type: none;
          background-color: transparent;
          .alert-danger {
            color: $red;
            background-color: transparent;
            border-color: transparent;
            padding: 0px;
            text-align: center;
          }
        }
        &.login-flipped {
          transform: rotateY(180deg);
        }
      }
    }
    .dialer-logo-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 5%;
      right: 9%;
      img {
        width: 100%;
      }
    }
    .dialer-social-icon-area {
      display: flex;
      z-index: 11;
      .social-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin: 10px;
        @include box-shadow-mixin(0px 3px 25px rgba(0, 0, 0, 0.09));
        width: 40px;
        height: 40px;
        @include border-radius(50%);
        &:hover {
          background-color: $primary;
          svg {
            color: $white !important;
            color: $white !important;
          }
        }
      }
    }
  }
  // dashboard loaded in iframe css start
  &.dashboard-iframe{
    // .login-banner-overlay , .bannerBg{
    //   display: none;
    // }
    .dialer-login{
      overflow-y: hidden;
      position: relative;
      padding:0px;
      .dialer-logo-area{
        display: none;
      }
      .dialer-login-inner{
        max-width: none;
        width: 100%;
        height: 100%;
        .dilaer-login-top{
          display: none;
        }
        iframe{
          width:100%;
          height:var(--inner-height);
        }
      }
    }   
  }
  // dashboard loaded in iframe css end
}

.organization-list {
  list-style-type: none;
  li {
    margin: 10px 0px;

    a {
      text-transform: capitalize;
      position: relative;
      span {
        text-decoration: underline;
        &:nth-child(2) {
          background: $purple;
          color: $white;
          text-decoration: none;
        }
        &:not {
          &:nth-child(1) {
            &:hover {
              .b-icon {
                scale: 1.2;
              }
            }
          }
        }
      }
    }
  }
}

// slide top to bottom animation
.slideBottom-enter-active {
  transition: all 0.4s ease-in;
}
.slideBottom-leave-active {
  transition: all 0.7s ease-in;
}
.slideBottom-enter {
  transform: translateY(-100%);
}
.slideBottom-leave-to {
  transform: translateY(-30%);
}

// slide bottom to top animation
.slideTop-enter-active {
  transition: all 0.2s linear;
}
.slideTop-leave-active {
  transition: all 0.4s linear;
}
.slideTop-enter {
  transform: translateY(200%);
}
.slideTop-leave-to {
  transform: translateY(100%);
}

// slide right to left animation
.slideLeft-enter-active {
  transition: all 0.4s linear;
}
.slideLeft-leave-active {
  transition: all 0.7s linear;
}
.slideLeft-enter {
  transform: translateX(200%);
}
.slideLeft-leave-to {
  transform: translateX(100%);
}

// zoom transition

.zoom-enter-active {
  transition: all 1.4s linear;
}
.zoom-leave-active {
  transition: all 1.8s linear;
}
.zoom-enter {
  transform: scale(0);
}
.zoom-leave-to {
  transform: scale(1);
}

// bounce transition

.bounce-enter-active {
  animation-duration: 2s;
  animation-name: bounce-spring;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
.bounce-leave-active {
  animation-name: bounce-spring;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes bounce-spring {
  0% {
    transform: scale(1, 1) translateY(2000px);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

// flip transition

.flip-enter-active {
  animation: flip 1s linear;
}
.flip-leave-active {
  animation: flip 2s linear;
}
@-webkit-keyframes flip {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}

@keyframes flip {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}

// shake animation

.shake-enter {
  transform: translateY(200%);
}
.shake-enter-active {
  animation-duration: 2s;
  animation-name: shake;
  animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
}
.shake-leave-active {
  animation-name: shake;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
}
.shake-leave-to {
  transform: translateY(100%);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

// end login page







@import "./assets/scss/styles.scss";
</style>

